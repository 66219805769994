import { css } from '@emotion/react'

const portfolioStyle = css`
  .title {
    font-family: 'Montserrat', 'Kosugi Maru', sans-serif;
    color: #3d3d3d;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    font-size: 2.6rem;

    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 1.8rem;
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 60px;
    max-width: 1280px;
    margin: 0 auto;

    @media (max-width: 767px) {
      row-gap: 20px;
    }
  }

  .row {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
`
export default portfolioStyle
