import React from 'react'
import { graphql, HeadProps, PageProps } from 'gatsby'
import Layout from '../components/layout'
import portfolioStyle from '../styles/templates/portfolioStyle'
import HeadTag from '../components/headTag'

const Portfolio: React.FC<PageProps<Queries.FetchPortfolioQuery>> = ({
  data,
}) => (
  <Layout>
    <div css={portfolioStyle}>
      <h1 className="title">{data.contentfulPortfolio?.title}</h1>
      <div className="container">
        {data.contentfulPortfolio?.photos?.map((photo) => (
          <div key={photo?.id} className="row">
            <img
              src={`${photo?.url}?fm=webp&w=900&h=900`}
              alt={`photo_${photo?.id}`}
            />
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default Portfolio

export const query = graphql`
  query FetchPortfolio($id: String!) {
    contentfulPortfolio(id: { eq: $id }) {
      id
      title
      slug
      photos {
        id
        url
      }
      ogImage {
        url
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export const Head = ({ data }: HeadProps<Queries.FetchPortfolioQuery>) => (
  <HeadTag
    title={data.contentfulPortfolio?.title}
    description={data.contentfulPortfolio?.title}
    ogUrl={`${data.site?.siteMetadata?.siteUrl}/portfolios/${data.contentfulPortfolio?.slug}`}
    ogImageUrl={`${data.contentfulPortfolio?.ogImage?.url}?fm=webp&w=1200&h=630&fit=crop`}
  />
)
